package com.picme.actuals

import com.lightningkite.kiteui.launchGlobal
import com.lightningkite.kiteui.models.ImageRemote
import com.lightningkite.kiteui.models.ImageResource
import com.lightningkite.kiteui.models.ImageSource
import com.lightningkite.kiteui.printStackTrace2
import com.lightningkite.kiteui.reactive.Readable
import com.lightningkite.kiteui.reactive.invoke
import com.lightningkite.kiteui.views.RContext
import com.lightningkite.kiteui.views.RView
import com.lightningkite.kiteui.views.ViewDsl
import com.lightningkite.kiteui.views.ViewWriter
import com.picme.Resources
import com.picme.currentCollection
import com.picme.sdk2.generated.AdSize
import com.picme.sdk2.generated.ListedAd
import com.picme.session
import kotlin.contracts.ExperimentalContracts
import kotlin.contracts.InvocationKind
import kotlin.contracts.contract

expect class Advertisement(context: RContext) : RView {
    var adUnitId: String?
    var adImgSrc: ImageSource?
    var fullSizeAd: Boolean?
    val adLoaded: Readable<Boolean?>
}

@OptIn(ExperimentalContracts::class)
@ViewDsl
inline fun ViewWriter.advertisement(setup: Advertisement.() -> Unit = {}): Advertisement {
    contract { callsInPlace(setup, InvocationKind.EXACTLY_ONCE) }
    return write(Advertisement(context), setup)
}

object AdCache {
    private val cachedAds = mutableListOf<ListedAd>()
    private fun List<ListedAd>.firstOfSize(size: AdSize): ListedAd? {
        return this.firstOrNull {
            if (size == AdSize.Small) it.size.value == 0
            else it.size.value > 0
        }
    }


    private suspend fun fetchMore(): List<ListedAd>? {
        return session()?.ad?.listAds(
            collectionId = currentCollection()?.collectionId?.raw,
            unitTest = false
        )?.ads?.also {
            cachedAds.addAll(it)
        }
    }

    suspend fun getAd(size: AdSize, contextId: String): ImageSource {
        try {
            val ad = cachedAds.firstOfSize(size) ?: fetchMore()?.firstOfSize(size)
            if (ad == null) return defaultAd(size)

            cachedAds.remove(ad)

//            launchGlobal {
//                session()?.ad?.recordImpression(
//                    collectionId = currentCollection()?.collectionId?.raw,
//                    contextId = contextId,
//                    adId = ad.adId
//                )
//            }

            return ad.uri.let(::ImageRemote)
        } catch (e: Exception) {
            e.printStackTrace2()
            return defaultAd(size)
        }
    }

    private fun defaultAd(size: AdSize): ImageResource {
        return if (size == AdSize.Large) Resources.picMePaigeAdVertical
        else Resources.picMePaigeAdVertical
    }
}